
import {computed, defineComponent, ref} from "vue";
import {useRoute} from "vue-router";
import { Field, Form, ErrorMessage} from "vee-validate";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import supplierType from "@/core/data/supplierTypetypes";
import * as Yup from "yup";
import AccountingCodesModal from "@/components/general-modals/accounting/AccountingCodes.vue";

export default defineComponent({
    name: "suppliers-update",
    data: function () {
        return {
        }
    },
    components: {
      Field,
      Form,
      ErrorMessage,
      AccountingCodesModal,
    },
    setup() {

      const submitButton1 = ref<HTMLElement | null>(null);
      const submitButton2 = ref<HTMLElement | null>(null);
      let refComp_AccountingCodeList = ref(null);

      const route = useRoute();
      const id = route.params.id;
      const payload = {ID: id}

      store.dispatch(Actions.SUPPLIERS_DETAILS, payload);
      const mySupplierDetails = computed(() => {
        return store.getters.suppliersDetails;
      });

      const supplierDetailsValidator = Yup.object().shape({
        Title: Yup.string().required("Doldurulması zorunlu"),
        Type: Yup.string().required("Doldurulması zorunlu"),
        Country: Yup.string().required("Doldurulması zorunlu"),
        City: Yup.string().required("Doldurulması zorunlu"),
        Address: Yup.string().required("Doldurulması zorunlu"),
      });

      const saveChanges1 = () => {
        if (submitButton1.value) {
          // Activate indicator
          submitButton1.value.setAttribute("data-kt-indicator", "on");

          const payload1 = {
            ID: mySupplierDetails.value.ID,
            Title: mySupplierDetails.value.Title,
            Type: mySupplierDetails.value.Type,
            Country: mySupplierDetails.value.Country,
            City: mySupplierDetails.value.City,
            Address: mySupplierDetails.value.Address,
            Phone: mySupplierDetails.value.Phone,
            Email: mySupplierDetails.value.Email,
          }
          //console.log(payload1);

          store.dispatch(Actions.SUPPLIERS_EDIT, payload1)
              .then(() => {
                Swal.fire({
                  text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Geri Dön!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(function () {
                  //
                });
                submitButton1.value?.removeAttribute("data-kt-indicator");
              }).catch(() => {
            Swal.fire({
              text: store.getters.getSuppliersErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton1.value?.removeAttribute("data-kt-indicator");
          });
        }
      };

      const saveChanges2 = () => {
        if (submitButton2.value) {
          // Activate indicator
          submitButton2.value.setAttribute("data-kt-indicator", "on");

          const payload2 = {
            ID: (mySupplierDetails.value as any).ID,
            Title: (mySupplierDetails.value as any).Title,
            Address: (mySupplierDetails.value as any).Address,
            Phone: (mySupplierDetails.value as any).Phone,
            Email: (mySupplierDetails.value as any).Email,
            TaxOffice: (mySupplierDetails.value as any).TaxOffice,
            TaxNumber: (mySupplierDetails.value as any).TaxNumber,
          }

          store.dispatch(Actions.SUPPLIERS_EDIT, payload2)
              .then(() => {
                Swal.fire({
                  text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Geri Dön!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(function () {
                  // Go to page after successfully login
                  //router.push({name: "userlist"});
                });
                submitButton2.value?.removeAttribute("data-kt-indicator");
              })
              .catch(() => {
                Swal.fire({
                  text: store.getters.getSuppliersErrors[0],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Tekrar dene!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
                submitButton2.value?.removeAttribute("data-kt-indicator");
              });

        }
      };

      store.dispatch(Actions.CITY_LIST, {});
      const myCities = computed(() => {
        return store.getters.CityList;
      });
      const filteredCity = computed(() => {
        if (myCities.value.data != null) {
          return (myCities.value.data).filter(citiesItem =>
              citiesItem.Country == (mySupplierDetails.value.Country).toLowerCase()
          );
        } else {
          return true;
        }
      });

      function setItems(ID) {
        (refComp_AccountingCodeList.value as any).sendAccountingCodeList("SUPPLIER", ID);
      }

        return {
          supplierType,
          supplierDetailsValidator,
          filteredCity,
          myCities,
          mySupplierDetails,
          refComp_AccountingCodeList,
          setItems,
          saveChanges1,
          saveChanges2,
          submitButton1,
          submitButton2,
        };
    },
});
